
import { useState, useEffect, useCallback, useRef } from 'react';
import Label from './Label';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper';

function Selector({ selected, onSelect, onTrashClick, dropDown }) {
  const [data, setData] = useState([]);

  const lastId = useRef(-1);

  const getLabel = useCallback((value) => {
    const result = selected?.filter(label => label.value === value);

    return result.length ? result[0] : {};
  }, [ selected ]);

  const handleDelete = useCallback((value) => {
    const selectedValues = selected.filter((selectedItem) => selectedItem.value !== value);

    onSelect(selectedValues);

    if (onTrashClick) {
      onTrashClick(selectedValues);
    }
  }, [selected, onSelect, onTrashClick]);

  const moveLabel = useCallback((hoverId) => {
    if (hoverId !== -1 && lastId.current !== hoverId) {
      lastId.current = hoverId;

      const list = [...selected].map((id, index) => ({
        id: id.value,
        dropId: index,
      }));

      const newSelected = update(list, {
          $splice: [
              [hoverId, 0, {id: -1, dropId: -1}],
          ],
      });

      setData(newSelected);
    }
  }, [data]);

  const dropLabel = useCallback((dragId) => {
    if (lastId.current !== -1) {
      const dragLabel = selected[dragId];

      const dropId = lastId.current - (dragId < lastId.current ? 1 : 0);

      const newSelected = update(selected, {
          $splice: [
              [dragId, 1],
              [dropId, 0, dragLabel],
          ],
      });

      lastId.current = -1;

      onSelect(newSelected);
    }

  }, [data]);

  useEffect(() => {
    setData([...selected].map(({value: id}, index) => ({
      id,
      dropId: index,
    })));
  }, [ selected ]);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div className='multiselect-selector'>
          { data?.map(({id, dropId}, index) => (
            id === -1 ? (
              <div className="multiselect-selector-drop" key={id}></div>
            ) : (
              <Label
                {...getLabel(id)}
                index={dropId}
                key={id}
                onDelete={handleDelete}
                isLast={index === selected.length - 1}
                moveLabel={moveLabel}
                dropLabel={dropLabel}
                dropDown={dropDown}
              ></Label>
            )
          )) }
        </div>
      </DndProvider>
    </>
  );
}

export default Selector;
