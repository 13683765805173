

export const getTreeOptions = (collections, prefix = null) => {
  return collections
    .filter(collection => !collection.is_group || (collection.children && collection.children?.length))
    .map(collection => ({
      value: prefix + collection.id,
      label: collection.name,
      children: collection.is_group ? getTreeOptions(collection.children) : null
    }));
}

export const findElement = (data, id) => {
  let result;

  if (Array.isArray(data)) {
    data?.forEach(el => {
      if (el.id === id) {
        result = el;
      }

      if (el.children) {
        const element = findElement(el.children, id);

        if (element) {
          result = element;
        }
      }
    });
  }

  return result;
}

export const getGroupCollections = (collections) => {
  const ids = [];

  collections?.forEach(collection => {
    if (collection.is_group) {
      if (collection.children?.length) {
        getGroupCollections(collection.children).forEach(id => {
          ids.push(id);
        });
      }
    } else {
      ids.push(collection);
    }
  });

  return ids;
}

export const getTreeIds = (values, collections) => {
  const ids = [];

  values.forEach(value => {
    const el = findElement(collections, value);

    if (el.is_group) {
      getGroupCollections(el.children).forEach(id => {
        ids.push(id);
      });
    } else {
      ids.push(el);
    }
  });

  return ids;
}

export const countChannels = (data) => {
  return data?.reduce((total, el) => {
    const values = el.value.split(',');

    return total + values.length;
  }, 0);
}