import React, { useContext, useReducer } from "react"

const ConfirmContext = React.createContext();

export const useConfirm = () => {
  return useContext(ConfirmContext);
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'show':
      return { ...state, visible: true, message: action.message, onConfirm: action.onConfirm }

    case 'hide':
      return { ...state, visible: false }

    default:
      return state;
  }
}

export const ConfirmProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    visible: false,
    message: 'Удалить?',
    onConfirm: () => {},
  });

  const show = (message, onConfirm) => dispatch({ type: 'show', message, onConfirm });
  const hide = () => dispatch({ type: 'hide' });

  return (
    <ConfirmContext.Provider value={{
      visible: state.visible,
      message: state.message,
      onConfirm: state.onConfirm,
      show,
      hide,
    }}>
      { children }
    </ConfirmContext.Provider>
  )
}