import React, { useEffect, useState } from 'react';
import CompositionBlock from './Block';

import './Composition.scss';

import {
  Box,
} from '@chakra-ui/react';

/**
 * корневой конмпонент для условий выборки
 * @param {*} props 
 * @returns 
 */
function Composition(props) {
  const [data, setData] = useState({});

  /**
   * инициализация данных
   */
  useEffect(() => {
    if (props.data) {
      const defaultData = props.data.filter(entry => entry.type === props.type);
      setData(defaultData && defaultData.length ? defaultData[0].data : {
        type: 'block',
        union: 'and',
        data: [ ]
      });
    } else {
      setData({
        type: 'block',
        union: 'and',
        data: [ ]
      });
    }
  }, [ ]);

  const onChangeHandle = (union, data, update = false) => {
    const newData = {
      type: 'block',
      union: union,
      data: data,
    };

    setData(newData);

    if (props.onChange) {
      props.onChange(newData);
    }
  }

  return props.type === props.selected ? (
    <Box>
      <CompositionBlock
        type={props.type}
        union={data.union}
        data={data.data}
        root={true}
        onChangeHandle={onChangeHandle}
        prefix={props.prefix}
      />
    </Box>
  ) : '';
}

export default Composition;
