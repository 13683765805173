import { useRef, useMemo } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { ReactComponent as Trash } from './images/trash.svg';

function Label({label, value, path, index, isLast, onDelete, moveLabel, dropLabel, dropDown }) {

  const deleteLabel = (value, event) => {
    onDelete(value);

    event.nativeEvent.preventDefault();
    event.preventDefault();
  }

  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: 'label',
    item: () => {
      return { index, isLast };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item) => {
      if (dropLabel) {
        dropLabel(item.index);
      }
    },
  });

  const [{ handlerId }, drop] = useDrop({
    accept: 'label',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item) {
      if (dropLabel) {
        dropLabel(item.index);
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleX= (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      if (moveLabel) {
        moveLabel(index + (hoverClientX > hoverMiddleX ? 1 : 0));
      }
    },
  });

  if (!!dropDown) {
    drag(drop(ref));
  }

  const opacity = isDragging ? 0.3 : 1;

  const fullLabel = useMemo(() => {
    return (path && path.length ? (path.join(' / ') + ": ") : "") + label;
  }, [ label, path ]);

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      className='multiselect-selector-label'
      data-index={index}
      key={value}
      style={{opacity}}
    >
      <div className={`multiselect-selector-label-name${dropDown ? '-dropdown' : ''}`}>{ fullLabel }</div>
      <div className='multiselect-selector-label-trash'><Trash onClick={(event) => deleteLabel(value, event)}/></div>
      { !isLast && <div>,</div> }
    </div>
  );
}

export default Label;